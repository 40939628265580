import React, { useState, useEffect } from 'react';
import Layout from '@components/layout';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import Moment from 'react-moment';

import SearchIcon from '@svg/search-black.svg';
import { Arrow } from '@svg/arrow';
import Image from 'gatsby-plugin-sanity-image';

import { GetEvents } from '@querys/getEvents';
import { GetBlogs } from '@querys/getBlogs';
import { GetPages } from '@querys/getPages';

const Search = (props) => {
  var now = new Date();

  const allEvents = GetEvents();
  const allBlogs = GetBlogs();
  const allPages = GetPages();
  const [events, setEvents] = useState(
    allEvents
      .filter(
        (event) => new Date(event.session[event.session.length - 1]) > now,
      )
      .sort((a, b) => new Date(a.session[0]) - new Date(b.session[0])),
  );
  const [blogs, setBlogs] = useState(allBlogs);
  const [pages, setPages] = useState(allPages);

  const [query, setQuery] = useState(
    (props.location.state && props.location.state.searchQuery) || '',
  );

  const [hover, setHover] = useState(false);
  const [otherHover, setOtherHover] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.innerWidth < 1024 && setIsMobile(true);
  }, []);

  let arrowVars = {};

  if (!isMobile) {
    arrowVars = {
      initial: {
        x: -20,
        transition: {
          duration: 0.3,
        },
      },
      animate: {
        x: 0,
        transition: {
          duration: 0.3,
        },
      },
    };
  }

  function filter(value) {
    return value
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .toLowerCase()
      .includes(query.toLowerCase());
  }

  useEffect(() => {
    let filteredEvents = allEvents
      .filter(
        (event) => new Date(event.session[event.session.length - 1]) > now,
      )
      .sort((a, b) => new Date(a.session[0]) - new Date(b.session[0]));
    let filteredBlogs = allBlogs;
    let filteredPages = allPages;

    filteredEvents = filteredEvents.filter(
      (event) =>
        (event.title && filter(event.title)) ||
        (event.where.title && filter(event.where.title)) ||
        (event.series && filter(event.series)) ||
        (event.where.location && filter(event.where.location)) ||
        (event.featuredArtist1 && filter(event.featuredArtist1.name)) ||
        (event.featuredArtist2 && filter(event.featuredArtist2.name)) ||
        (event.featuredArtist3 && filter(event.featuredArtist3.name)) ||
        (event.settings.searchExcerpt &&
          filter(event.settings.searchExcerpt)) ||
        (event.session[0] &&
          filter(
            new Date(event.session[0]).toLocaleString('en-us', {
              weekday: 'long',
              month: 'long',
              year: 'numeric',
            }),
          )) ||
        (event.session[1] &&
          filter(
            new Date(event.session[1]).toLocaleString('en-us', {
              weekday: 'long',
              month: 'long',
              year: 'numeric',
            }),
          )) ||
        (event.session[2] &&
          filter(
            new Date(event.session[2]).toLocaleString('en-us', {
              weekday: 'long',
              month: 'long',
              year: 'numeric',
            }),
          )) ||
        (event.session[3] &&
          filter(
            new Date(event.session[3]).toLocaleString('en-us', {
              weekday: 'long',
              month: 'long',
              year: 'numeric',
            }),
          )) ||
        (event.repertoire[0] && filter(event.repertoire[0].heading)) ||
        (event.repertoire[0] && filter(event.repertoire[0].text)) ||
        (event.repertoire[1] && filter(event.repertoire[1].heading)) ||
        (event.repertoire[1] && filter(event.repertoire[1].text)) ||
        (event.repertoire[2] && filter(event.repertoire[2].heading)) ||
        (event.repertoire[2] && filter(event.repertoire[2].text)) ||
        (event.repertoire[3] && filter(event.repertoire[3].heading)) ||
        (event.repertoire[3] && filter(event.repertoire[3].heading)) ||
        (event?.meta?.metaTitle && filter(event?.meta?.metaTitle)) ||
        (event?.meta?.metaDescription &&
          filter(event?.meta?.metaDescription)) ||
        (event?.meta?.metaKeywords &&
          filter(event?.meta?.metaKeywords.toString())),
    );

    filteredBlogs = filteredBlogs.filter(
      (blog) =>
        (blog?.title && filter(blog?.title)) ||
        (blog?.excerpt && filter(blog?.excerpt)) ||
        (blog?.meta?.metaTitle && filter(blog?.meta?.metaTitle)) ||
        (blog?.meta?.metaDescription && filter(blog?.meta?.metaDescription)) ||
        //convert metaKeywords to a stirng
        (blog?.meta?.metaKeywords &&
          filter(blog?.meta?.metaKeywords.toString())),
    );

    filteredPages = filteredPages.filter(
      (page) =>
        (page?.title && filter(page?.title)) ||
        (page?.meta?.metaTitle && filter(page?.meta?.metaTitle)) ||
        (page?.meta?.metaDescription && filter(page?.meta?.metaDescription)) ||
        //convert metaKeywords to string
        (page?.meta?.metaKeywords &&
          filter(page?.meta?.metaKeywords.toString())),
    );

    setEvents(filteredEvents);
    setBlogs(filteredBlogs);
    setPages(filteredPages);
  }, [query]);

  return (
    <Layout>
      <div className='grid grid-cols-14 pt-28 lg:pt-64 pb-24 lg:pb-52'>
        <span className='col-start-2 col-end-14 text-sm leading-sm md:text-lg md:leading-lg -mb-4 z-10'>
          Search
        </span>
        <div className='search-page border-b border-grey flex flex-row col-start-2 col-end-14'>
          <input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder='Type to search'
            className='lg:font-regular text-lg lg:text-6xl leadin-lg lg:leading-6xl h-16 lg:h-32 bg-white text-black w-full focus:outline-none'
          />
          <button className='pl-6 lg:pl-12'>
            <SearchIcon className='w-5 lg:w-10' />
          </button>
        </div>

        <div className='mt-12 lg:mt-20 col-start-2 col-end-14'>
          <span className='text-sm leading-sm md:text-lg md:leading-lg'>
            {events.length + blogs.length + pages.length} Results
          </span>

          {/* Events Results */}
          <div className='mt-2 lg:mt-12'>
            {events.map((e, i) => {
              return (
                <div
                  className={`grid grid-cols-12 ${i !== 0 && 'mt-12'}`}
                  key={i}>
                  <div className='mt-10 lg:mt-0 row-start-2 row-end-3 lg:row-start-1 lg:row-end-2 col-start-1 col-end-13 lg:col-end-7 lg:mr-12 overflow-hidden'>
                    {e.listingImage && (
                      <Link to={`/events/${e.slug.current}`}>
                        <Image
                          className='transform hover:scale-105 duration-300 ease-in-out m-0-important'
                          {...e.listingImage.image}
                        />
                      </Link>
                    )}
                  </div>

                  <div className='col-start-1 lg:col-start-7 col-end-13 flex flex-col justify-center'>
                    <div className='prose md:prose-lg col-span-1 flex flex-col justify-center text-black'>
                      <div className='mt-8 lg:mt-16'>
                        <div className='border-b border-grey w-full'>
                          <p>{e?.series}</p>
                          <h2 className='font-medium'>{e?.title}</h2>
                        </div>

                        <div className='border-b border-grey w-full grid grid-cols-10'>
                          <p className='col-span-3 lg:col-span-2'>When:</p>

                          <div className='col-start-5 lg:col-start-4 col-span-6'>
                            <p key={i}>
                              <Moment withTitle format='D MMM YYYY - h:mm A'>
                                {e?.session[0]}
                              </Moment>
                            </p>
                          </div>
                        </div>

                        <div className='border-b border-grey w-full grid grid-cols-10'>
                          <p className='col-span-3 lg:col-span-2'>Where:</p>
                          <p className='col-start-5 lg:col-start-4 col-span-5'>
                            {e?.where?.title}
                          </p>
                        </div>

                        {!e.isOneOff && (
                          <div className='mt-8 lg:mt-14 flex justify-around sm:justify-start xl:block'>
                            <div className='inline'>
                              <Link
                                to={`/events/${e.slug.current}`}
                                className='btn-white'>
                                Concert Info
                              </Link>
                            </div>
                            <div className='inline sm:ml-4 '>
                              <Link
                                to='/season-subscription/select'
                                className='btn-black'>
                                Subscribe Now
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Blogs Results */}
          <div className='mt-16 lg:mt-28 flex flex-col gap-y-6 lg:gap-y-12'>
            {blogs?.map((b, i) => {
              return (
                <div key={i} className='pb-4 lg:pb-12 border-b border-grey'>
                  <h5 className='text-xl leading-xl md:text-3xl md:leading-3xl font-semibold'>
                    {b?.title}
                  </h5>
                  <p className='my-2 lg:my-5 text-sm leading-sm md:text-base md:leading-base'>
                    {b?.excerpt}
                  </p>
                  <div className='w-full flex flex-row justify-between items-center lg:mt-4'>
                    <Link
                      onMouseEnter={() => setOtherHover(i)}
                      onMouseLeave={() => setOtherHover(false)}
                      to={`/news/${b?.slug?.current}`}
                      className='font-semibold mt-0-important text-sm leading-sm md:text-lg md:leading-lg'>
                      Read article
                    </Link>
                    <motion.span
                      variants={arrowVars}
                      initial='initial'
                      animate={otherHover === i ? 'animate' : 'initial'}>
                      <Arrow white={false} />
                    </motion.span>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Pages Results */}
          <div className='flex flex-col gap-y-6 lg:gap-y-12 mt-6 lg:mt-12'>
            {pages?.map((b, i) => {
              return (
                <div key={i} className='pb-4 lg:pb-12 border-b border-grey'>
                  <h5 className='text-xl leading-xl md:text-3xl md:leading-3xl font-semibold'>
                    {b?.title}
                  </h5>
                  <div className='w-full flex flex-row justify-between items-center lg:mt-4'>
                    <Link
                      onMouseEnter={() => setOtherHover(b)}
                      onMouseLeave={() => setOtherHover(false)}
                      to={`/${b?.slug?.current}`}
                      className='font-semibold mt-0-important text-sm leading-sm md:text-lg md:leading-lg'>
                      View Page
                    </Link>
                    <motion.span
                      variants={arrowVars}
                      initial='initial'
                      animate={otherHover === b ? 'animate' : 'initial'}>
                      <Arrow white={false} />
                    </motion.span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
