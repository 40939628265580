import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const GetPages = () => {
  const { allSanityPage } = useStaticQuery(graphql`
    query {
      allSanityPage {
        nodes {
          _key
          _type
          slug {
            current
          }
          title
          meta {
            metaKeywords
            metaTitle
            metaDescription
          }
        }
      }
    }
  `);
  return allSanityPage.nodes || [];
};
