import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const GetBlogs = () => {
  const { allSanityBlog } = useStaticQuery(graphql`
    query {
      allSanityBlog(sort: { fields: [date], order: DESC }) {
        nodes {
          _key
          _type
          blogColor {
            hex
          }
          content: _rawContent(resolveReferences: { maxDepth: 100 })
          date(locale: "NZ", formatString: "DD MMM YYYY")
          excerpt
          category
          slug {
            current
          }
          featuredImage {
            image {
              ...ImageWithPreview
            }
          }
          external
          title
          meta {
            metaKeywords
            metaTitle
            metaDescription
          }
        }
      }
    }
  `);
  return allSanityBlog.nodes || [];
};
